<template>
  <v-main class="d-flex justify-center align-center">
    <v-container>
      <v-card-title>{{ $t('pages.error.title') }}</v-card-title>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'PageError',
  }
</script>
